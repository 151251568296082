import { lazy } from "react";
export const Layout = lazy(() => import("./components/Layouts/Layout.tsx"));
export const Protected = lazy(() => import("./components/Protected.tsx"));
export const Unauthorized = lazy(() => import("./components/Unauthorized.tsx"));
export const Error = lazy(() => import("./error.tsx"));
export const Onboarding = lazy(() => import("./pages/auth/onboarding.tsx"));
export const SignIn = lazy(() => import("./pages/auth/signIn.tsx"));
export const SignUp = lazy(() => import("./pages/auth/signUp.tsx"));
export const Help = lazy(() => import("./pages/dashboard/help.tsx"));
export const App = lazy(() => import("./pages/dashboard/index.tsx"));
export const MyRentings = lazy(() => import("./pages/dashboard/MyRentals/myRentals.tsx"));
export const PartnerNetwork = lazy(() => import("./pages/dashboard/partnernetwork.tsx"));
export const Notifications = lazy(() => import("./pages/dashboard/notifications.tsx"));
export const Profile = lazy(() => import("./pages/dashboard/Profile/Profile.tsx"));
export const Users = lazy(() => import("./pages/dashboard/users.tsx"));