import React, { createContext, useContext, useState, ReactNode } from 'react';

interface IUserContext {
  name: string;
  setName: (name: string) => void;
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void; 
}

const UserContext = createContext<IUserContext>({
  name: '',
  setName: () => {},
  selectedFile: null,
  setSelectedFile: () => {},
});


export const useUser = () => useContext(UserContext);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [name, setName] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null); 

  return (
    <UserContext.Provider value={{ name, setName, selectedFile, setSelectedFile }}>
      {children}
    </UserContext.Provider>
  );
};