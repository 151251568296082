import Lottie from "lottie-react";
import SpinnerLoding from "@/assets/animation/Spinner_Ejari.json";

const Spinner = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 z-50">
      <Lottie
        animationData={SpinnerLoding}
        style={{ width: 200, height: 200 }}
      />
    </div>
  );
};

export default Spinner;
