import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import posthog from 'posthog-js';
import { PostHogProvider } from "posthog-js/react";
import React, { lazy, Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { HelmetProvider } from "react-helmet-async";
import { I18nextProvider, useTranslation } from "react-i18next";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "remixicon/fonts/remixicon.css";
import Spinner from "./components/Spinner.tsx";
import { UserProvider } from "./contexts/UserContext.tsx";
import {
  App,
  Help,
  Layout,
  MyRentings,
  Notifications,
  Onboarding,
  PartnerNetwork,
  Profile,
  Protected,
  SignIn,
  SignUp,
  Unauthorized,
  Users,
} from "./file.ts";
import "./i18n";
import i18n from "./i18n";
import "./styles/globals.css";

posthog.init('phc_bgyoIbNWGx0pOTiAqRTr3BFzPTcmi37veXlfoTc6xOd',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)
posthog.capture("my event", { property: "value" });

export const Error = lazy(() => import("./error.tsx"));

posthog.capture("$pageview");

function Main() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const textDirectionClass = i18n.language.startsWith("ar")
      ? "text-rtl "
      : "text-ltr";

    document.body.className = textDirectionClass;
  }, [i18n.language]);

  return (
    <Suspense
      fallback={
        <div>
          <Spinner />
        </div>
      }
    >
      <RouterProvider router={router} />
    </Suspense>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
      queries: {
          staleTime: 3600 * 60 * 60 * 60,
          refetchOnWindowFocus: false,
      },
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Unauthorized>
        <App />
      </Unauthorized>
    ),
  },
  {
    path: "/",
    element: (
      <Protected>
        <Layout />
      </Protected>
    ),
    errorElement: <Error />,
    children: [
      {
        path: "dashboard",
        element: <App />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "help",
        element: <Help />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "myRentals",
        element: <MyRentings />,
      },
      {
        path: "PartnerNetwork",
        element: <PartnerNetwork />,
      },
      {
        path: "notifications",
        element: <Notifications />,
      },
    ],
  },
  {
    path: "/auth/",
    children: [
      {
        path: "signIn",
        element: (
          <GoogleReCaptchaProvider
            reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
          >
            <SignIn />
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: "signUp",
        element: (
          <GoogleReCaptchaProvider
            reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
          >
            <SignUp />
          </GoogleReCaptchaProvider>
        ),
      },
      {
        path: "Information",
        element: (
          <GoogleReCaptchaProvider
            reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
          >
            <Onboarding />
          </GoogleReCaptchaProvider>
        ),
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
]);

const helmetContext = {};

const rootMap = new WeakMap();

const container = document.getElementById("root");
if (container) {
  let root;
  if (!rootMap.has(container)) {
    root = ReactDOM.createRoot(container);
    rootMap.set(container, root);
  } else {
    root = rootMap.get(container);
  }

  root.render(
    <React.StrictMode>
      <PostHogProvider client={posthog}>
        <HelmetProvider context={helmetContext}>
          <I18nextProvider i18n={i18n}>
            <UserProvider>
              <QueryClientProvider client={queryClient}>
                <Main />
              </QueryClientProvider>
            </UserProvider>
          </I18nextProvider>
        </HelmetProvider>
      </PostHogProvider>
    </React.StrictMode>
  );
}
